import arcalab_logo from '../images/arcalab_logo_branca.png'
import { useState, useEffect } from 'react';
import CancelIcon from '@mui/icons-material/Cancel'
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import BlockIcon from '@mui/icons-material/Block';
import VerifiedIcon from '@mui/icons-material/Verified';
import PaidIcon from '@mui/icons-material/Paid';
import axios from 'axios';
import QRCode from 'qrcode'
import {v4} from 'uuid'
import { Termos } from './Termos';
import { municipios, uf } from './municipios';
import { MetaPixel } from './Facebook';
import { ImageSlider } from './Slider';
import { sendMail } from './SendMail';
import Lucchini from '../images/LUCCHINI.jpg'
import Fibra_1 from '../images/FIBRA 1.jpg'
import Fibra_2 from '../images/FIBRA 2.jpg'
import Fibra_3 from '../images/FIBRA 3.jpg'
import Fibra_4 from '../images/FIBRA 4.jpg'
import Fibra_5 from '../images/FIBRA 5.jpg'
import parte_5_thumb from '../images/thumbs/parte_5_thumb.png'
import StarsIcon from '@mui/icons-material/Stars';
import LockIcon from '@mui/icons-material/LockOpen';


export function LucchiniCompWeb(){
    return(
        <div style={{marginTop:'3vh', marginLeft:'40px'}}>
        <div style={{width:'180px', height:'180px', backgroundImage:'url("'+Lucchini+'")', backgroundSize:'cover', borderRadius:'50%', zIndex:'1', position:'absolute'}}>

        </div>
        <div style={{width:'80%',backgroundColor:'#161616', transform:'translate(50px, 20px)', zIndex:'-1', paddingBottom:'15px'}}>
            <div style={{padding:'3px 8px 3px 8px',width:'max-content', backgroundColor:'#0c76ab', marginLeft:'120px', transform:'translate(0, -50%)', color:"#fff", fontFamily:'arial black', fontSize:'12px', borderRadius:'5px', backgroundPosition:'center'}}>
                GABRIEL LUCCHINI
            </div>
            <div style={{color:'#ececec', fontFamily:'Arial Black', fontSize:'12px', textAlign:'left', marginLeft:'140px', width:'70%', lineBreak:'auto', lineHeight:'14px'}}>
                Engenheiro de gravação e mixagem e produtor musical, indicado múltiplas vezes ao LATIN GRAMMY, responsável por trabalhos com artistas como L7NNON, Japa, Ludmilla e Martinho da Vila. Tem mais de 10 anos de experiência em estúdio, além de créditos em produções de trilhas para televisão, cinema e publicidade.
            </div>
        </div>
    </div>
    )
}

export function LucchiniCompMobile(){
    return(
    <div style={{marginTop:'3vh', marginLeft:'0'}}>
        <div style={{float:'left', marginTop:'40px'}}>
            <div style={{width:'100px', height:'100px', backgroundImage:'url("'+Lucchini+'")', backgroundSize:'cover', borderRadius:'50%', zIndex:'1', marginLeft:'15px'}}/>
            <div style={{padding:'3px 8px 3px 8px',width:'max-content', backgroundColor:'#0c76ab',color:"#fff", fontFamily:'arial black', fontSize:'11px', borderRadius:'5px', backgroundPosition:'center', transform:'translate(0, -120%)', position:'absolute', zIndex:'1'}}>
                GABRIEL LUCCHINI
            </div>
        </div>
        <div style={{width:'40vw',backgroundColor:'#161616', transform:'translate(0px, 0px)', zIndex:'-1', paddingBottom:'15px', float:'left', color:'#ececec', fontFamily:'Arial Black', fontSize:'12px', textAlign:'left',lineBreak:'auto', lineHeight:'14px', padding:'3px 8px 3px 8px', marginLeft:'20px'}}>
            Engenheiro de gravação e mixagem e produtor musical, indicado múltiplas vezes ao LATIN GRAMMY, responsável por trabalhos com artistas como L7NNON, Japa, Ludmilla e Martinho da Vila. Tem mais de 10 anos de experiência em estúdio, além de créditos em produções de trilhas para televisão, cinema e publicidade.
        </div>
        <div style={{clear:'both'}}/>
        

    </div>
    )
}

const price_line_through = {
    backgroundColor: 'transparent',
    color: '#ed2f2f',
    fontSize:'22px',
    backgroundImage: 'repeating-linear-gradient(170deg, transparent 0%, transparent 48%, #ed2f2f 50%, transparent 54%, transparent 100%)',
}

export function ArcalabInscricao(props:any){

    const valor = {
        presencial: 330,
        online: 110,
        presencial_de:499.99,
        online_de:199.99,
    }
    const cupons:any = {
        ESTACIO10: 10,
        INSTA10: 10,
        ALUNO10: 10,
    }
    const lotacao:any = {
      presencial: 13,
      online: 20
    }
    const data = '04/05/2025'

    const [online, setOnline] = useState(true)
    const [pix, setPix] = useState(true)
    const [cupom, setCupom] = useState(props.cupom!=undefined?props.cupom:'')
    const [cupomState, setCupomState] = useState((props.cupom) in cupons?1:0)
    const [preco, setPreco] = useState<any>({str:String(valor.presencial).replace('.',','), num:valor.presencial})
    const [display, setDisplay] = useState(0)
    const [customerInfo, setCustomerInfo] = useState({nome:'', email:'', cpf:'', cep:'', uf:'', cidade:'', endereco:'', numero:''})
    const [payload, setPayload] = useState('')
    const [validate, setValidate] = useState({name:true, cpf:true, email:true, repeat:true, cep:true, endereco:true, numero:true})
    const [soldout, setSoldout] = useState({presencial:false, online:false})
    const [registered, setRegistered] = useState(false)
    const [state, setState] = useState('RJ')
    const [citylist, setCitylist] = useState([{Id:0, Codigo:0, Nome:'', Uf:''}])
    const [currentCity, setCurrentCity] = useState('Rio de Janeiro')
    const [loadingPayment, setLoadingpayent] = useState(false)
    const [redirLink, setRedirlink] = useState('')

    function roundValor(valor:number){
        let rounded = Math.ceil(valor*100)/100
        if(Number.isInteger(rounded)){
            return({str: rounded+',00', num:rounded})
        } else {
            return({str:String(rounded).replace('.',','), num:rounded})
        }
    }

    async function checkSoldout(){
        const options ={
            url:'https://us-central1-arcaentretenimento.cloudfunctions.net/app/arcalab_firestore',
            method:'POST',
            headers: {
                accept: 'application/json',
                'content-type': 'application/json',
            },
            data:{
                type:'get_soldout'
            }
        }
        axios.request(options)
        .then((res)=>{
            console.log(res.data)
            const soldoutObj = res.data
            let parcial_soldout = {presencial:false, online:false}
              if(soldoutObj.online>=lotacao.online){
                parcial_soldout.online = true
            }
            if(soldoutObj.presencial>=lotacao.presencial){
                parcial_soldout.presencial = true
            }
            if(parcial_soldout.online||parcial_soldout.presencial){
                setSoldout(parcial_soldout)
            }
        })
    }

    useEffect(()=>{
        let newCitylist = municipios.filter((value)=>value.Uf==state)
        setCitylist(newCitylist)
        if(state=='RJ'){
            setCurrentCity('Rio de Janeiro')
        }
    },[state])

    useEffect(()=>{
        let newCitylist = municipios.filter((value)=>value.Uf==state)
        setCitylist(newCitylist)
      if(soldout.presencial==false&&soldout.online==false){
        checkSoldout()
      }
        if(display==0){
            let formaElement = document.getElementById("forma")! as HTMLInputElement
            formaElement.addEventListener('change', function(){
                if(formaElement.value=='pix'){
                    setPix(true)
                } else {
                    setPix(false)
                }
            })
            let ufElement = document.getElementById("uf")! as HTMLInputElement
            ufElement.addEventListener('change', function(){
                setState(ufElement.value)
            })
        }
    },[])

    useEffect(()=>{
      if(soldout.presencial&&!soldout.online){
        setOnline(true)
      }
      if(!soldout.presencial&&soldout.online){
        setOnline(false)
      }
      if(soldout.presencial&&soldout.online){
        setDisplay(2)
      }
    },[soldout])

    const taxas = [1.0529, 1.0831, 1.0948, 1.1065, 1.1182, 1.1299, 1.1518, 1.1635, 1.1752, 1.1869, 1.1986, 1.2103]

    useEffect(()=>{
        let total = 0
        if(online){
            total = valor.online
        } else {
            total = valor.presencial
        }
        if(!pix){
            total = total*taxas[0]
        }
        if(cupomState==1){
            total = total*(1-(cupons[cupom]/100))
        }
        setPreco(roundValor(total))
    },[online, pix, cupomState])

    const inputStyle =(width='480px')=> {
        return(
            {
        backgroundColor:'#1b1b1b',
        padding:'12px',
        border:'2px solid #393939',
        outline:'none',
        borderRadius:'10px',
        color:'#fff',
        height:'20px',
        width:width,
            }
        )
    }
    
    const inputStyleInvalid = (width='480px')=> {
        return({
      backgroundColor:'#1b1b1b',
      padding:'12px',
      border:'2px solid #ff5733',
      outline:'none',
      borderRadius:'10px',
      color:'#fff',
      height:'20px',
      width:width
    })
  }
    const inputStyle2 =(width='400px')=> {
        return({
        backgroundColor:'#1b1b1b',
        border:'2px solid #393939',
        outline:'none',
        borderRadius:'5px 0 0 5px',
        color:'#fff',
        height:'20px',
        width:width,
    })
    }

    const inputStyle3 = {
        backgroundColor:'#1b1b1b',
        padding:'12px',
        border:'2px solid #393939',
        outline:'none',
        borderRadius:'10px',
        color:'#fff',
        height:'20px',
        width:'200px',
    }

    function goCupom(){
        let element = document.getElementById("nomecupom")! as HTMLInputElement
        const text = element.value.toUpperCase()
        if(text in cupons){
            setCupomState(1)
            setCupom(text)
        } else {
            setCupomState(2)
        }
    }

    function getTransform(maxWidth:number){
        if(props.width<maxWidth){
            return(0)
        } else {
            return((props.width/2)-(maxWidth/2))
        }
    }

    const faqs = {
        question: [
            'Preciso ter experiencia previa para participar do laboratorio?',
            'Preciso saber utilizar alguma daw ou software especifico?',
            'Ate quando posso realizar minha inscricao?',
            'Como funcionara a dinamica online?',
            'O video on-line sera gravado e disponibilizado apos a data do laboratorio?',
            'Os arquivos das mixagens serao disponibilizados para download?',
            'Fiz o pagamento porem nao recebi confirmacao via e-mail',
            'Tenho outra duvida que nao foi respondida aqui.'
        ],
        answer: [
            'Nao e necessario ter experiencia pois o laboratorio e destinado a todos os niveis.',
            'O laboratorio sera realizado em pro tools, porem nao e necessario que os participantes dominem o software.',
            'As inscricoes nao tem prazo determinado, porem as como as vagas sao limitadas, o laboratorio esta sujeito a lotacao.',
            'O laboratorio sera realizado presencialmente no estudio e transmitido online pelo zoom. Um horario especifico sera destinado para perguntas e respostas dos participantes on-line.',
            'A gravacao transmitida on-line sera disponibilizada por um periodo apos a realizacao do laboratorio.',
            'Como as mixagens estudadas sao de projetos reais, nao disponibilizaremos os arquivos, respeitando os direitos do artista em questao.',
            'Se voce se certificou que o pagamento foi efetuado, envie um e-mail para contato@arcaentretenimento.com.br descrevendo o problema e anexando o comprovante de pagamento.',
            'Entre em contato atraves do e-mail contato@arcaentretenimento.com.br'
        ],
    }

    function cleanCPF(raw_cpf:String){
      let clean = ''
      const valid = ['0','1','2','3','4','5','6','7','8','9']
      for(let a=0;a<raw_cpf.length;a++){
        if(valid.includes(raw_cpf.charAt(a))){
          clean=clean+raw_cpf.charAt(a)
        }
      }
      return(clean)
    }

    useEffect(()=>{
        if(display==1){

            let orderObject = {
                id: v4(),
                nome:customerInfo.nome,
                email:customerInfo.email,
                cpf: customerInfo.cpf,
                cep: customerInfo.cep,
                uf: customerInfo.uf,
                cidade: customerInfo.cidade,
                endereco: customerInfo.endereco,
                numero: customerInfo.numero,
                online: online,
                pix:pix&&'pix'||'cartao',
                valor:preco.num,
                data: new Date().toJSON().slice(0,10),
                cupom: cupom
            }

            const options = {
                url:'https://us-central1-arcaentretenimento.cloudfunctions.net/app/arcalab_firestore',
                method:'POST',
                headers: {
                    accept: 'application/json',
                    'content-type': 'application/json',
                },
                data:{
                    type:'payment_pix',
                    orderObject: orderObject
                }
              }
              axios.request(options)
              .then((res)=>{
                    const payload = res.data.payload
                    setPayload(payload)
                    if(!props.mobile){
                     const canvas = document.getElementById('PixQr')
                     QRCode.toCanvas(canvas, payload)
                    }
              })
        }
    },[display])

    async function addEmailInterested(){
      const email_int = (document.getElementById("email_interested")! as HTMLInputElement).value

      const options = {
        url:'https://us-central1-arcaentretenimento.cloudfunctions.net/app/arcalab_firestore',
        method:'POST',
        headers: {
            accept: 'application/json',
            'content-type': 'application/json',
        },
        data:{
            type:'mail_interested',
            email_int:email_int
        }
      }
      axios.request(options)
      .then(()=>{
        setRegistered(true)
      })
    }

    function validate_data(nome:string, cpf:string, email:string,repeat:string,cep:string,endereco:string,numero:string){
      let isValid = {name:false, cpf:false, email:false, repeat:false, cep:false, endereco:false, numero:false}
      if(nome.length>4){
        isValid.name = true
      }
      if(cpf.length==11){
        isValid.cpf = true
      }
      if(email.length>4){
        isValid.email = true
      }
      if(repeat==email){
        isValid.repeat = true
      }
      if(cep.length==8){
        isValid.cep = true
      }
      if(endereco.length>4){
        isValid.endereco = true
      }
      if(numero.length>0){
        isValid.numero = true
      }
      return(isValid)
    }

    async function handlePayment(){
        setLoadingpayent(true)
        const nome = document.getElementById("nome")! as HTMLInputElement
        const email = document.getElementById("email")! as HTMLInputElement
        const repeat = document.getElementById("emailrepeat")! as HTMLInputElement
        const cpf = cleanCPF((document.getElementById("cpf")! as HTMLInputElement).value)
        const cep = cleanCPF((document.getElementById("cep")! as HTMLInputElement).value)
        const uf = document.getElementById("uf")! as HTMLInputElement
        const cidade = document.getElementById("cidade")! as HTMLInputElement
        const endereco = document.getElementById("endereco")! as HTMLInputElement
        const numero = document.getElementById("numero")! as HTMLInputElement
        
        const valObj:any = validate_data(nome.value, cpf, email.value.toUpperCase(), repeat.value.toUpperCase(), cep, endereco.value, numero.value)
        let val = true
        Object.keys(valObj).forEach((key)=>{
          if(!valObj[key]){
            val = false
          }
        })

        if(val){
        setCustomerInfo({nome:nome.value, email:email.value, cpf:cpf, cep:cep, uf:uf.value, cidade:cidade.value, endereco:endereco.value, numero:numero.value})
          let orderObject = {
            id: '',
            nome:nome.value,
            email:email.value.toUpperCase(),
            cpf: cpf,
            cep: cep,
            uf: uf.value,
            cidade: cidade.value,
            endereco: endereco.value,
            numero: numero.value,
            online: online,
            pix:pix&&'pix'||'cartao',
            valor:preco.num,
            data: new Date().toJSON().slice(0,10),
            cupom: cupom
        }
          let modo = 'Online'
          if(!online){
              modo = 'Presencial'
          }
          if(pix){
              setDisplay(1)
          }
          if(!pix){
              let inst_array:any = []
              taxas.forEach((numb, index)=>{
                  let preco_adj = roundValor((preco.num/taxas[0])*numb)
                  let current = {number: index+1, total:Number((preco_adj.num*100).toFixed(0))}
                  inst_array.push(current)
              })
              const options = {
                url:'https://us-central1-arcaentretenimento.cloudfunctions.net/app/arcalab_firestore',
                method:'POST',
                headers: {
                    accept: 'application/json',
                    'content-type': 'application/json',
                },
                data:{
                    type:'payment_card',
                    inst_array: inst_array,
                    orderObject: orderObject
                }
              }
              axios.request(options)
              .then((res)=>{
                setRedirlink(res.data.payment_url)
                window.open(res.data.payment_url, '_self');
              })
          }
        } else {
            setLoadingpayent(false)
          setValidate(valObj)
        }
    }

    function FibraFotosWeb(){
        return(
        <div style={{width:'100%',marginTop:'20px', height:'400px', position:'absolute', left:'0', maxWidth:'1600px', transform:'translate('+getTransform(1600)+'px, 0)'}}>
            <div style={{float:'left', height:'400px', width:'33.333%', backgroundImage:'url("'+Fibra_1+'")', backgroundSize:'cover', backgroundPositionX:'right'}}></div>
            <div style={{float:'left', height:'400px', width:'33.333%', backgroundImage:'url("'+Fibra_5+'")', backgroundSize:'cover', backgroundPositionX:'center'}}></div>
            <div style={{float:'left', height:'400px', width:'33.334%', backgroundImage:'url("'+Fibra_2+'")', backgroundSize:'cover', backgroundPositionX:'left'}}></div>
            <div style={{clear:'both'}}/>
        </div>
        )
    }
    
    function FibraFotosMobile(props:any){
        return(
        <div style={{width:'100%',marginTop:'20px', height:'250px', position:'absolute', left:'0', maxWidth:'1600px', transform:'translate('+getTransform(1600)+'px, 0)'}}>
            {props.id==1&&(
            <div style={{float:'left', height:'250px', width:'100%', backgroundImage:'url("'+Fibra_1+'")', backgroundSize:'cover', backgroundPositionX:'center'}}></div>
            )}
            {props.id==2&&(
            <div style={{float:'left', height:'250px', width:'100%', backgroundImage:'url("'+Fibra_2+'")', backgroundSize:'cover', backgroundPositionX:'center'}}></div>
            )}
            {props.id==3&&(
            <div style={{float:'left', height:'250px', width:'100%', backgroundImage:'url("'+Fibra_5+'")', backgroundSize:'cover', backgroundPositionX:'center'}}></div>
            )}
        </div>
        )
    }

    return(
        <div>
            <MetaPixel/>
            <div style={{width:props.mobile?'80vw':'700px', height:'200px', margin:'5vh auto'}}>
                    {/* <img src={arcalab_logo} width={props.mobile ? '180px' : '300px'}/> */}
                    <div style={{fontFamily:'Digital', color:'#ececec', marginTop:'5vh',marginBottom:'5vh', textAlign:'left', lineHeight:props.mobile?'14px':'20px', fontSize:props.mobile?'10px':'12px'}}>
                        O estudio Fibra e um dos mais renomados do mercado, recebendo diariamente artistas consagrados como Ludmilla, Anitta, L7nnon, Martinho da Vila, Tiago Iorc, entre outros.
                    </div>
                    <ImageSlider mobile={props.mobile} width={props.width}/>

                    <div style={{fontFamily:'Digital', color:'#ececec', marginTop:'5vh', textAlign:'left', lineHeight:props.mobile?'14px':'20px', fontSize:props.mobile?'10px':'12px'}}>
                        Guiado por um engenheiro premiado com diversas indicaCOes ao Latin Grammy, vamos abrir sessoes de mixagem de artistas reais para estudar tecnicas de mixagens usadas nos maiores hits do brasil, descobrindo quais plug-ins utilizar, e aprendendo como e quando usar equipamentos analogicos.
                    </div>

                    {props.mobile?<LucchiniCompMobile/>:<LucchiniCompWeb/>}

                    <div style={{fontFamily:'Digital', color:'#ececec', textAlign:'left', lineHeight:props.mobile?'14px':'20px', fontSize:props.mobile?'10px':'12px', marginTop:props.mobile?'4vh':'8vh'}}>
                        O laboratorio oferece a oportunidade de criar networking e trocar conhecimento com a equipe do estudio e outros participantes do workshop. Alem da modalidade presencial, os participantes podem optar pela transmissao online, que sera transmitida atraves do zoom e tera um numero limitado de vagas para maior aproveitamento.
                    </div>

                    {props.mobile?<FibraFotosMobile id={1}/>:<FibraFotosWeb/>}
                    
                    <div style={{width:props.mobile?'80vw':'500px', margin:props.mobile?'290px auto 0px':'440px auto 2vh'}}>
                        <div style={{backgroundColor:'#161616', borderRadius:'5px 5px 0 0', fontSize:'10px', fontFamily:'Digital', textAlign:'left', color:"#ececec", padding:'7px'}}>
                            informacoes
                        </div>
                        <div style={{border:'2px solid #161616', borderRadius:'0 0 5px 5px', fontFamily:'Digital', color:"#fff", fontSize:props.mobile?'10px':'12px', textAlign:'left', padding:'7px',lineHeight:'15px'}}>
                            DATA: {data}<br/>
                            HORARIO: 10:00 as 15:00<br/>
                            {/* CRONOGRAMA:<br/> */}
                            LOTACAO: PRESENCIAL ({lotacao.presencial}) / ONLINE ({lotacao.online})
                            <div>
                                <div style={{float:'left', marginRight:'5px'}}><VerifiedIcon sx={{fontSize:'12px', color:"#058cdf"}}/></div>
                                <div style={{float:'left', wordWrap:'break-word', width:props.mobile?'70vw':''}}>Certificado de Conclusao Digital</div>
                                <div style={{clear:'both'}}/>
                            </div>
                            <div>
                                <div style={{float:'left', marginRight:'5px'}}><PaidIcon sx={{fontSize:'12px', color:"#03ad01"}}/></div>
                                <div style={{float:'left', wordWrap:'break-word', width:props.mobile?'70vw':''}}>Descontos em futuros laboratorios</div>
                                <div style={{clear:'both'}}/>
                            </div>
                            LOCAL: ESTUDIO FIBRA<br/>
                            AV. DAS AMERICAS - 2000 - RIO DE JANEIRO/RJ
                        </div>

                    </div>
                    <div style={{width:props.mobile?'80vw':'600px', margin:props.mobile?'0px auto 5vh':'0 auto',border:'1px solid hsl(46, 100%, 50%)', borderRadius:'5px', display:props.mobile?'grid':'', placeItems:'center'}}>
                        <div style={{float:props.mobile?'none':'left', textAlign:'left', maxWidth:'380px', marginTop:props.mobile?'12px':'22px'}}>
                            <div style={{marginLeft:'5px'}}>
                                <div style={{float:'left', color:'hsl(46, 100%, 50%)', transform:'translateY(-4px) scale(0.85)', marginRight:'3px'}}><StarsIcon/></div>
                                <div style={{fontFamily:'Digital', color:'hsl(46, 100%, 50%)', fontSize:'15px', float:'left'}}>BONUS</div>
                                <div style={{clear:'both'}}/>
                            </div>
                            <div style={{fontFamily:'Digital', color:'#ececec', fontSize:'13px', marginLeft:'10px'}}>5 horas de conteudo da primeira edicao do Arcalab: gravando uma banda em estudio (bateria, baixo, teclado e guitarra)</div>
                        </div>
                            <div style={{float:props.mobile?'none':'left', width:props.mobile?170*16/9:120*16/9, height:props.mobile?'170px':'120px', marginTop:'10px', backgroundImage:`url('${parte_5_thumb}')`, backgroundSize:'cover', borderRadius:'3px', marginBottom:props.mobile?'15px':'1vh', transform:props.mobile?'none':'translateX(-10px)'}}>
                            <div style={{backgroundColor:"rgba(0,0,0,0.2)", height:'100%', width:'100%'}}>
                                        <div style={{color:"#fff", position:'relative', top:'50%', transform:'translateY(-50%)'}}>
                                            <LockIcon style={{transform:'scale(1.3)'}}/>
                                        </div>
                                    </div>
                            </div>
                        {!props.mobile&&(<div style={{clear:'both'}}/>)}
                    </div>

                    {display==0&&(
                        <div style={{fontFamily:'Arial', color:'#fff', textAlign:'left', width:props.mobile?'80vw':'510px', margin:'2vh auto 0'}}>
                                <div>
                                    <div>
                                        Nome Completo
                                    </div>
                                    <div>
                                        <input type='text' id='nome' style={validate.name&&(props.mobile?inputStyle('75vw'):inputStyle())||(props.mobile?inputStyleInvalid('75vw'):inputStyleInvalid())} autoComplete='off'/>
                                    </div>
                                </div>
                                <div style={{marginTop:'5px'}}>
                                    <div>
                                        CPF
                                    </div>
                                    <div>
                                        <input type='text' id='cpf' style={validate.cpf&&(props.mobile?inputStyle('75vw'):inputStyle())||(props.mobile?inputStyleInvalid('75vw'):inputStyleInvalid())} autoComplete='off'/>
                                    </div>
                                </div>
                                <div style={{marginTop:'5px'}}>
                                    <div>
                                        Email
                                    </div>
                                    <div>
                                        <input type='text' id='email' style={validate.email&&(props.mobile?inputStyle('75vw'):inputStyle())||(props.mobile?inputStyleInvalid('75vw'):inputStyleInvalid())} autoComplete='off'/>
                                    </div>
                                </div>
                                <div style={{marginTop:'5px'}}>
                                    <div>
                                        Confirme o Email
                                    </div>
                                    <div>
                                        <input type='text' id='emailrepeat' style={validate.repeat&&(props.mobile?inputStyle('75vw'):inputStyle())||(props.mobile?inputStyleInvalid('75vw'):inputStyleInvalid())} autoComplete='off'/>
                                    </div>
                                </div>
                                <div style={{marginTop:'5px'}}>
                                        <div style={{float:'left', border:'2px solid #fff', padding:soldout.presencial?'5px 10px':'10px', borderRadius:'10px', textDecoration: soldout.presencial?'line-through':'none' , cursor:soldout.presencial ? 'default' : 'pointer', backgroundColor: soldout.presencial ? '#393939' : online ? 'transparent' : '#00952d', fontSize:'12px', marginRight:'20px'}} onClick={()=>{if(!soldout.presencial){setOnline(false)}}}>
                                        PRESENCIAL
                                        {soldout.presencial&&(<div style={{color:'#ff5733', fontSize:'9px', textAlign:'center'}}><span style={{display:'inline-block'}}>ESGOTADO</span></div>)}
                                    </div>
                                    <div style={{float:'left', border:'2px solid #fff', padding:soldout.online?'5px 10px':'10px', borderRadius:'10px', textDecoration: soldout.online?'line-through':'none', cursor:soldout.online ? 'default' :'pointer', backgroundColor: soldout.online ? '#393939' : online ? '#00952d' : 'transparent', fontSize:'12px'}} onClick={()=>{if(!soldout.presencial){setOnline(true)}}}>
                                        ON-LINE
                                        {soldout.online&&(<div style={{color:'#ff5733', fontSize:'9px', textAlign:'center'}}><span style={{display:'inline-block'}}>ESGOTADO</span></div>)}
                                    </div>
                                    <div style={{clear:'both'}}/>
                                </div>
                                <div style={{marginTop:'5px'}}>
                                    <div style={{float:'left', fontSize:'14px'}}>
                                        Forma de pagamento
                                    </div>
                                    <div style={{float:'right'}}>
                                        <select id='forma'>
                                            <option value='pix'>Pix (-4%)</option>
                                            <option value='cartao'>Cartão de Débito/Crédito</option>
                                        </select>
                                    </div>
                                    <div style={{clear:'both'}}/>
                                </div>
                                <div style={{marginTop:'5px'}}>
                                    <div>
                                        <div style={{float:'left', fontSize:'14px'}}>
                                            Cupom de Desconto
                                        </div>
                                        <div style={{float:'right', fontSize:'10px', lineHeight:'18px'}}>
                                            {cupomState==0&&('')}
                                            {cupomState==1&&('10% OFF')}
                                            {cupomState==2&&('Cupom invalido')}
                                        </div>
                                        <div style={{clear:'both'}}/>
                                    </div>
                                    {cupomState!=1&&(
                                        <div>
                                            <div style={{float:'left'}}>
                                                <input type='text' id='nomecupom' style={props.mobile?inputStyle2('51vw'):inputStyle2()} autoComplete='off'/>
                                            </div>
                                            <div style={{backgroundColor:'#393939', border:'2px solid #393939', borderRadius:'0 5px 5px 0',color:'#fff',height:'22px',width:props.mobile?'90px':'95px',float:'left', cursor:'pointer', fontSize:'10px', textAlign:'center', lineHeight:'22px', fontFamily:'Digital'}} onClick={()=>{goCupom()}}>
                                                aplicar
                                            </div>
                                            <div style={{clear:'both'}}/>
                                        </div>
                                    )||(
                                        <div style={{borderRadius:'5px', backgroundColor:'#2bc459', display:'inline-block', padding:'5px', fontSize:'10px', cursor:'default'}}>
                                            <div style={{float:'left'}}>
                                                {cupom}
                                            </div>
                                            <div style={{float:'left', cursor:'pointer', marginLeft:'5px'}} onClick={()=>{setCupomState(0);setCupom('')}}>
                                                <CancelIcon sx={{fontSize:'10px'}}/>
                                            </div>
                                            <div style={{clear:'both'}}/>
                                        </div>
                                    )}
                                    
                                </div>
                                <div style={{marginTop:'5px', fontSize:'26px'}}>
                                    <span style={{fontSize:'12px'}}>de </span>
                                    <span style={price_line_through}>R${online?String(valor.online_de):String(valor.presencial_de)}</span>
                                    <span style={{fontSize:'12px'}}> por </span>
                                    R${preco.str}
                                    {props.mobile&&(<br/>)}
                                    <span style={{fontSize:'12px'}}> {pix?'ou ':''}em até 12x{pix?' no cartão de crédito':''} </span>
                                </div>
                                <div style={{marginTop:'5px'}}>
                                    <div style={{float:props.mobile?'none':'left', marginBottom:props.mobile?'10px':''}}>
                                        <div>
                                            CEP
                                        </div>
                                        <div>
                                            <input type='text' id='cep' style={validate.cep&&(props.mobile?inputStyle('75vw'):inputStyle('200px'))||(inputStyleInvalid('200px'))} autoComplete='off'/>
                                        </div>
                                    </div>
                                    <div style={{float:'left', marginLeft:props.mobile?'':'10px'}}>
                                        <div>
                                            UF
                                        </div>
                                            <ExpandMoreIcon style={{position:"absolute", color:'#fff', fontSize:'18px', transform:props.mobile?'translateY(15px) translateX(20vw)':'translateY(15px) translateX(35px)' }}/>
                                            <select id='uf' style={{backgroundColor:'#1b1b1b', border:'2px solid #393939', outline:'none',borderRadius:'10px',color:'#fff',padding:'15px 0 15px 15px', width:props.mobile?'26vw':'60px', WebkitAppearance:'none'}} defaultValue='RJ'>
                                                {uf.map((estado:any, ind:any)=>{
                                                    return(
                                                        <option key={ind} value={estado.sigla}>{estado.sigla}</option>
                                                    )
                                                })}
                                            </select>
                                        <div>
                                        </div>
                                    </div>
                                    <div style={{float:'left', marginLeft:'10px'}}>
                                        <div>
                                            Cidade
                                        </div>
                                        <div>
                                            <ExpandMoreIcon style={{position:"absolute", color:'#fff', fontSize:'18px', transform:'translateY(15px) translateX(175px)', }}/>
                                            <select id='cidade' style={{backgroundColor:'#1b1b1b', border:'2px solid #393939', outline:'none',borderRadius:'10px',color:'#fff',width:'200px', padding:'15px', WebkitAppearance:'none'}} value={currentCity} onChange={(e)=>{setCurrentCity(e.target.value)}}>
                                                {citylist.map((cidade:any, ind:any)=>{
                                                    return(
                                                        <option key={ind} value={cidade.Nome}>{cidade.Nome}</option>
                                                    )
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                    <div style={{clear:'both'}}/>
                                </div>
                                <div>
                                    <div style={{float:props.mobile?'none':'left', marginTop:'5px'}}>
                                        <div>
                                            Endereço
                                        </div>
                                        <div>
                                            <input type='text' id='endereco' style={validate.endereco&&(props.mobile?inputStyle('75vw'):inputStyle('380px'))||(props.mobile?inputStyleInvalid('75vw'):inputStyleInvalid('380px'))} autoComplete='off'/>
                                        </div>
                                    </div>
                                    <div style={{float:props.mobile?'none':'left', marginTop:'5px', marginLeft:props.mobile?'':'10px'}}>
                                        <div>
                                            Numero
                                        </div>
                                        <div>
                                            <input type='text' id='numero' style={validate.numero&&(inputStyle('60px'))||(inputStyleInvalid('60px'))} autoComplete='off'/>
                                        </div>
                                    </div>
                                    <div style={{clear:'both'}}/>
                                </div>
                                <Accordion style={{backgroundColor:'#1b1b1b', color:'#a2a2a2', fontSize:'12px', lineHeight:'12px', margin:'5px 0 10px', borderRadius:"5px"}}>
                                        <AccordionSummary
                                        expandIcon={<ExpandMoreIcon sx={{color:'#fff'}}/>}
                                        aria-controls={'terms-content'}
                                        id={'terms-header'}
                                        >
                                        Ao prosseguir para o pagamento, declaro que li e concordo com os Termos e Condições abaixo
                                        </AccordionSummary>
                                        <AccordionDetails>
                                          {Termos.map((part, ind)=>{
                                            return(<div key={ind} style={{fontSize:ind%2&&('10px')||('12px'), marginBottom:ind%2&&('4px')||('0')}}>{part.toUpperCase()}</div>)
                                          })}
                                        </AccordionDetails>
                                    </Accordion>
                                <div style={{border:'2px solid #fff', padding:'10px', borderRadius:'10px', cursor:'pointer', marginTop:'5px', textAlign:'center', fontFamily:'Digital', fontSize:'12px'}} onClick={()=>{if(!loadingPayment){handlePayment()}}}>
                                        {loadingPayment?'REDIRECIONANDO...':'IR PARA PAGAMENTO'}
                                </div>
                                {redirLink&&(
                                <div style={{fontSize:props.mobile?'13px':'14px',marginTop:'5px',color:'#a2a2a2', wordWrap:'break-word'}}>
                                    Caso não seja redirecionado automáticamente, copie o link e cole em uma nova aba:<br/>
                                    {redirLink}
                                </div>)}
                            </div>
                    )}
                    {display==1&&(
                        <div>
                            {!props.mobile&&(<div>
                            <div style={{fontFamily:'Digital', color:'#fff', marginTop:'5vh', textAlign:'center', marginBottom:'20px'}}>
                                Escaneie o QR Code no App do seu banco
                            </div>
                            <div>
                                <canvas id='PixQr'/>
                            </div>
                            </div>
                            )}
                            <div style={{fontFamily:'Digital', color:'#fff', marginTop:'20px', textAlign:'center', marginBottom:'20px'}}>
                                {props.mobile?'realize o pagamento com o pix copia e cola':'Ou utilize o pix copia e cola'}
                            </div>
                            <div style={{border:'2px solid #3a3a3a', backgroundColor:'#1b1b1b', borderRadius:'10px', padding:'15px', width:props.mobile?'70vw':'400px', color:'#fff', margin:'0 auto', wordWrap:'break-word', fontSize:props.mobile?'14px':''}}>
                                {payload}
                            </div>
                        </div>
                    )}
                    {display==2&&(
                      <div>
                        <div style={{fontFamily:'Digital', color:'#fb6060', marginTop:props.mobile?'1vh':'5vh', textAlign:'center', marginBottom:'20px'}}>
                          Vagas Esgotadas
                        </div>
                        <BlockIcon style={{color:'#fb6060', fontSize:'60px'}}/>
                        <div style={{fontFamily:'Arial', color:'#fff', textAlign:'left', width:props.mobile?'75vw':'510px', margin:'5px auto 0'}}>
                                    <div style={{fontSize:props.mobile?'14px':''}}>
                                        Cadastre seu E-mail para ser notificado das próximas datas
                                    </div>
                                    <div>
                                        <input type='text' id='email_interested' style={props.mobile?inputStyle('68vw'):inputStyle()} autoComplete='off'/>
                                    </div>
                                    <div style={{border:!registered?'2px solid #fff':'2px solid #57e155', padding:'10px', borderRadius:'10px', cursor:!registered?'pointer':'default', marginTop:'5px', textAlign:'center', fontFamily:'Digital', fontSize:'12px',color:!registered?'#fff':'#57e155'}} onClick={()=>{if(!registered){addEmailInterested()}}}>
                                        {!registered&&('CADASTRAR')||('E-mail cadastrado')}
                                    </div>
                          </div>
                        </div>
                    )}

                    {props.mobile&&(<FibraFotosMobile id={2}/>)}

                    <div style={{fontFamily:'Digital', color:'#fff', marginTop:props.mobile?'300px':'5vh', textAlign:'left'}}>
                        FAQ
                        <div style={{marginTop:'5px'}}>
                            {faqs.question.map((question:any, index:any)=>{
                                return(
                                    <Accordion key={index} style={{backgroundColor:'#1b1b1b', color:'#ececec', fontSize:props.mobile?'10px':'12px', lineHeight:props.mobile?'14px':'16px'}}>
                                        <AccordionSummary
                                        expandIcon={<ExpandMoreIcon sx={{color:'#fff'}}/>}
                                        aria-controls={'panel'+index+'-content'}
                                        id={'panel'+index+'-header'}
                                        style={{fontSize:props.mobile?'10px':''}}
                                        >
                                        {question}
                                        </AccordionSummary>
                                        <AccordionDetails>
                                        {faqs.answer[index]}
                                        </AccordionDetails>
                                    </Accordion>
                                )
                            })}
                        </div>
                    </div>
            {props.mobile&&(<FibraFotosMobile id={3}/>)}
            </div>
        </div>
    )
}