import {useState, useEffect} from 'react'
import CryptoJS from 'crypto-js'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { CircularProgress } from '@mui/material'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

export function ForgotPassComponent(props:any){

    const [validate, setValidate] = useState({email:true, pass:true, repeat:true})
    const [status, setStatus] = useState(0)
    const [isLoading, setIsLoading] = useState(false)

    const navigate = useNavigate()

    useEffect(()=>{
        if(props.forgotPass){
            setStatus(2)
        }
    },[])

    function validate_data1(email:string){
        let isValid = {email:false}
        if(email.length>4){
            isValid.email = true
        }
        return(isValid)
    }

    function validate_data2(pass:string, repeat:string){
        let isValid = {repeat:false, pass:false}
        if(pass.length>4){
            isValid.pass = true
        }
        if(repeat==pass){
            isValid.repeat = true
        }
        return(isValid)
    }

    async function handleForgot(){
        const email = document.getElementById("email")! as HTMLInputElement
        const valObj:any = validate_data1(email.value)
        let val = true
        Object.keys(valObj).forEach((key)=>{
          if(!valObj[key]){
            val = false
          }
        })
        if(val){
            const options = {
                url:'https://us-central1-arcaentretenimento.cloudfunctions.net/app/auth/forgot',
                method:'POST',
                headers: {
                    'content-type': 'application/json',
                },
                data:{
                    email: email.value.toUpperCase(),
                },
                // withCredentials: true
            }
            setIsLoading(true)
            await axios.request(options)
            .then((res)=>{
                if(res.status==200){
                    setStatus(1)
                }
            })
            .catch((err)=>{
                setIsLoading(false)
            })
        } else {
            setValidate(valObj)
        }
    }

    async function handleReset(){

        const pass = document.getElementById("password")! as HTMLInputElement
        const repeatPass = document.getElementById("password")! as HTMLInputElement
        const valObj:any = validate_data2(pass.value, repeatPass.value)
        let val = true
        Object.keys(valObj).forEach((key)=>{
          if(!valObj[key]){
            val = false
          }
        })
        if(val){
            const options = {
                url:'https://us-central1-arcaentretenimento.cloudfunctions.net/app/auth/resetpass',
                method:'POST',
                headers: {
                    'content-type': 'application/json',
                },
                data:{
                    code: props.forgotPass,
                    password: CryptoJS.SHA256(pass.value).toString()
                },
                // withCredentials: true
            }
            setIsLoading(true)
            await axios.request(options)
            .then((res)=>{
                if(res.status==200){
                    setStatus(3)
                }
            })
            .catch((err)=>{
                setIsLoading(false)
            })
        } else {
            setValidate(valObj)
        }
    }

    const inputStyle =(width='20vw',valid=true)=> {
        return(
            {
        backgroundColor:'#fff',
        padding:'12px',
        border:`1px solid ${valid?'#c7c7c7':'#fd3939'}`,
        outline:'none',
        borderRadius:'10px',
        color:'#000',
        height:'20px',
        width:width,
            }
        )
    }

    function MainForgot(){
        return(
            <div style={{fontFamily:'Inter', fontWeight:'600', textAlign:'center'}}>
                <div style={{maxWidth:'300px', marginBottom:'20px', fontWeight:'300', fontSize:'13px'}}>
                    Digite seu e-mail para receber um link com instruções de recuperação de senha.
                </div>
                <div>
                    <div style={{textAlign:'left'}}>E-mail</div>
                    <div>
                        <input type='text' id='email' style={inputStyle(props.mobile?'60vw':undefined,validate.email?true:false)} autoComplete='off'/>
                    </div>
                </div>
                <div style={{marginTop:'10px', display:'inline-block'}}>
                    {!isLoading?(
                        <div>
                            <div>
                                <div style={{backgroundColor:'#000', color:'#fff', padding:props.mobile?'7px 20px':'10px 30px', cursor:'pointer', float:'left', borderRadius:'20px'}} onClick={()=>{handleForgot()}}>
                                    Recuperar Senha
                                </div>
                                <div style={{clear:'both'}}/>
                            </div>
                        </div>
                    ):(
                        <div>
                            <CircularProgress/>
                        </div>
                    )}
                </div>
            </div>
        )
    }
    
    function Forgot2(){
        return(
            <div style={{display:'grid', placeItems:'center'}}>
                <div style={{marginBottom:'10px', color:'hsl(168, 76%, 36%)'}}>
                    <CheckCircleIcon style={{transform:'scale(1.3)'}}/>
                </div>
                <div style={{maxWidth:'300px', fontWeight:'300', fontSize:'13px'}}>
                        Clique no link enviado por e-mail para recuperar sua senha. Verifique também o lixo eletrônico ou caixa de spam.
                </div>
            </div>
        )
    }

    function Forgot3(){
        return(
            <div style={{fontFamily:'Inter', fontWeight:'600', textAlign:'center'}}>
                <div>
                    <div style={{textAlign:'left'}}>Nova Senha</div>
                    <div>
                        <input type='password' id='password' style={inputStyle(props.mobile?'60vw':undefined,validate.pass?true:false)} autoComplete='off'/>
                    </div>
                </div>
                <div>
                    <div style={{textAlign:'left'}}>Repetir Senha</div>
                    <div>
                        <input type='password' id='repeatpassword' style={inputStyle(props.mobile?'60vw':undefined,validate.repeat?true:false)} autoComplete='off'/>
                    </div>
                </div>
                <div style={{marginTop:'10px', display:'inline-block'}}>
                    {!isLoading?(
                        <div>
                            <div>
                                <div style={{backgroundColor:'#000', color:'#fff', padding:props.mobile?'7px 20px':'10px 30px', cursor:'pointer', float:'left', borderRadius:'20px'}} onClick={()=>{handleReset()}}>
                                    Redefinir Senha
                                </div>
                                <div style={{clear:'both'}}/>
                            </div>
                        </div>
                    ):(
                        <div>
                            <CircularProgress/>
                        </div>
                    )}
                </div>
            </div>
        )
    }

    function Forgot4(){
        return(
            <div style={{display:'grid', placeItems:'center'}}>
                    <div style={{marginBottom:'10px', color:'hsl(168, 76%, 36%)'}}>
                        <CheckCircleIcon style={{transform:'scale(1.3)'}}/>
                    </div>
                <div style={{fontFamily:'Inter', fontWeight:'600', textAlign:'center', marginBottom:'20px'}}>
                    Senha redefinida.
                </div>
                <div style={{fontFamily:'Inter', fontWeight:'600', backgroundColor:'#000', color:'#fff', padding:props.mobile?'7px 20px':'10px 30px', cursor:'pointer', float:'left', borderRadius:'20px'}} onClick={()=>{navigate('/arcalab/login')}}>
                    Login
                </div>
            </div>
        )
    }

    return(
        <div>
            <div style={{margin:'0 auto', backgroundColor:'#eee', borderRadius:'10px', width:'fit-content', height:'fit-content', textAlign:'left', padding:'30px'}}>
                {status==0&&(<MainForgot/>)}
                {status==1&&(<Forgot2/>)}
                {status==2&&(<Forgot3/>)}
                {status==3&&(<Forgot4/>)}
            </div>
        </div>
    )
}