import arcalab_logo from '../images/arcalab_logo_branca.png'
import { useState, useEffect, useRef } from 'react';
import { PlaylistVideo } from './PlaylistVideo';
import { CreateAccount } from './CreateAccount';
import {Route, Routes, useNavigate} from 'react-router-dom'
import { LoginComponent } from './Login';
import Layout from './utils/Layout'
import RequireAuth from './utils/RequireAuth';
import useAuth from './hooks/useAuth';
import { Menu } from './Menu'
import { MainPage } from './MainPage';
import { ArcalabInscricao } from './ArcalabInscricao';
import { ForgotPassComponent } from './ForgotPassword';
import useRefreshToken from './hooks/useRefreshToken';

export function Arcalab(props:any){

    const navigate = useNavigate()
    const refresh = useRefreshToken()
    const {auth}:any = useAuth()
    const [firstRender, setFirstRender] = useState(true)

    useEffect(()=>{
        if(firstRender&&!auth?.email){
            refresh()
        }
        if(props.verifyMail){
            navigate('/arcalab/account')
        }
        if(props.forgotPass){
            navigate('/arcalab/forgot')
        }
        setFirstRender(false)
    },[])

    return(
        <div>
            <Menu/>
            <div style={{width:props.mobile?'80vw':'85vw', height:'200px', margin:'5vh auto'}}>
                    <div style={{marginBottom:'5vh'}}>
                        <img src={arcalab_logo} width={props.mobile ? '180px' : '300px'} style={{cursor:'pointer'}} onClick={(()=>{navigate('/arcalab')})}/>
                    </div>
                <Routes>
                    <Route path='/' element={<Layout/>}>
                        {/* <Route path='/' element={<MainPage width={props.width} mobile={props.mobile}/>}/> */}
                        <Route path='/' element={<ArcalabInscricao width={props.width} mobile={props.mobile} cupom={props.cupom}/>}/>
                        <Route path='/account' element={<CreateAccount width={props.width} mobile={props.mobile} verifyMail={props.verifyMail}/>}/>
                        <Route path='/login' element={<LoginComponent width={props.width} mobile={props.mobile}/>}/>
                        <Route path='/forgot' element={<ForgotPassComponent width={props.width} mobile={props.mobile} forgotPass={props.forgotPass}/>}/>
                        <Route element={<RequireAuth/>}>
                            <Route path='/videos' element={<PlaylistVideo width={props.width} mobile={props.mobile}/>}/>
                        </Route>
                    </Route>
                </Routes>
                
            </div>
        </div>
    )
}