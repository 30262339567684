import { useState, useEffect, useRef } from 'react';
import useAuth from './hooks/useAuth';
import VideoPlayer from './VideoPlayer';
import parte_1_thumb from '../images/thumbs/parte_1_thumb.png'
import parte_2_thumb from '../images/thumbs/parte_2_thumb.png'
import parte_3_thumb from '../images/thumbs/parte_3_thumb.png'
import parte_4_thumb from '../images/thumbs/parte_4_thumb.png'
import parte_5_thumb from '../images/thumbs/parte_5_thumb.png'
import parte_6_thumb from '../images/thumbs/parte_6_thumb.png'
import parte_7_thumb from '../images/thumbs/parte_7_thumb.png'
import parte_8_thumb from '../images/thumbs/parte_8_thumb.png'
import LockIcon from '@mui/icons-material/Lock';
import { isSafari } from 'react-device-detect'

export function PlaylistVideo(props:any){

    const {auth}:any = useAuth()

    function iOS() {
        return [
          'iPad Simulator',
          'iPhone Simulator',
          'iPod Simulator',
          'iPad',
          'iPhone',
          'iPod'
        ].includes(navigator.platform)
        // iPad on iOS 13 detection
        || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
      }

    function isAllowed(){
        let allowed = false
        if(auth.roles.includes(1)){
            allowed = true
        }
        return(allowed)
    }

    // const baseURL = {
    //     dash:'https://d5e0wghb9awyn.cloudfront.net/',
    //     hls:'https://d5e0wghb9awyn.cloudfront.net/'
    // }
    // const parts = [
    //     {hls:'a65e861a-770b-46e2-b497-990802bbfa5d/manifest.m3u8', dash:'a65e861a-770b-46e2-b497-990802bbfa5d/manifest.mpd', thumb:parte_1_thumb},
    //     {hls:'f580ecfc-a9cc-4dc1-af63-51c2ed376a95/manifest.m3u8', dash:'f580ecfc-a9cc-4dc1-af63-51c2ed376a95/manifest.mpd', thumb:parte_2_thumb},
    //     {hls:'2d6b693b-e43f-4b2d-b97f-3c68523a25a6/manifest.m3u8', dash:'2d6b693b-e43f-4b2d-b97f-3c68523a25a6/manifest.mpd', thumb:parte_3_thumb},
    //     {hls:'134769bf-5042-4536-9bd6-1b028e1122a6/manifest.m3u8', dash:'134769bf-5042-4536-9bd6-1b028e1122a6/manifest.mpd', thumb:parte_4_thumb},
    //     {hls:'59535747-3ec5-4cbb-9349-21cb4eafb29e/manifest.m3u8', dash:'59535747-3ec5-4cbb-9349-21cb4eafb29e/manifest.mpd', thumb:parte_5_thumb},
    //     {hls:'96146b93-1199-405a-adb7-8acb77099d15/manifest.m3u8', dash:'96146b93-1199-405a-adb7-8acb77099d15/manifest.mpd', thumb:parte_6_thumb},
    //     {hls:'c3d4b2ec-ef34-4c7b-937a-8a285eb1e33c/manifest.m3u8', dash:'c3d4b2ec-ef34-4c7b-937a-8a285eb1e33c/manifest.mpd', thumb:parte_7_thumb},
    //     {hls:'f5e0261b-ce0f-42df-a419-54ac1778063b/manifest.m3u8', dash:'f5e0261b-ce0f-42df-a419-54ac1778063b/manifest.mpd', thumb:parte_8_thumb},
    // ]

    const parts = [
        {id:'_1Nc_zTc40U', thumb: parte_1_thumb},
        {id:'th6-S2aH9EQ', thumb: parte_2_thumb},
        {id:'ljwoFLb6Fmg', thumb: parte_3_thumb},
        {id:'s50Nv80aYTQ', thumb: parte_4_thumb},
        {id:'yzpgYDOm-SU', thumb: parte_5_thumb},
        {id:'0lk5gD_kQI4', thumb: parte_6_thumb},
        {id:'jL3rJuHopA0', thumb: parte_7_thumb},
        {id:'RfHU73XAb2o', thumb: parte_8_thumb},
    ]

    // function videoJsOptions(id:string) {
    //     return(
    //         {            autoplay: false,
    //         controls: true,
    //         responsive: true,
    //         fluid: true,
    //         playbackRates: [1, 1.5, 2],
    //         sources: [{
    //             src: iOS()||isSafari?baseURL.hls+id:baseURL.dash+id,
    //             type: iOS()||isSafari?'application/x-mpegURL':'application/dash+xml'

    //         }]}
    //     )
    // }

    const [currentId, setCurrentId] = useState(0)

    return(
        <div>
            <div style={{float:props.mobile?'none':'left', overflow:'hidden', borderRadius:props.mobile?'0px':'10px', position:props.mobile?'absolute':'relative',left:'0'}}>
                {isAllowed()
                ?
                // <VideoPlayer options={videoJsOptions(iOS()||isSafari?parts[currentId].hls:parts[currentId].dash)} width={props.mobile?'100vw':'60vw'}/>
                <iframe style={{border:'none'}} width={props.mobile?props.width:props.width*0.6} height={props.mobile?props.width*9/16:props.width*0.6*9/16} src={"https://www.youtube.com/embed/"+parts[currentId].id} title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe>
                :
                <div style={{width:props.mobile?'100vw':'60vw', height:(props.width)/16*9, backgroundImage:`url(${parte_5_thumb})`, backgroundSize:'cover'}}></div>
                }
                
            </div>
            <div style={{float:props.mobile?'none':'left', height:props.mobile?'':(props.width*0.6)/16*9, width:props.mobile?'80vw':'20vw', marginLeft:props.mobile?'0px':'50px', overflowY:props.mobile?'hidden':'scroll', transform:props.mobile?'translateY('+(((props.width)/16*9)+10)+'px)':''}}>
                {parts.map((id, key)=>{
                        return(
                            <div className={currentId==key?'videoSelected':'videoPart'} key={key} style={{
                                color:"#fff",
                                cursor:'pointer',
                                height:'100px',
                            }} onClick={()=>{setCurrentId(key)}}>
                                <div style={{float:'left', width:80*16/9, height:'80px', marginTop:'10px', backgroundImage:`url('${id.thumb}')`, backgroundSize:'cover'}}>
                                    {!isAllowed()&&(
                                    <div style={{backgroundColor:"rgba(0,0,0,0.6)", height:'100%', width:'100%'}}>
                                        <div style={{color:"#fff", position:'relative', top:'50%', transform:'translateY(-50%)'}}>
                                            <LockIcon style={{transform:'scale(1.3)'}}/>
                                        </div>
                                    </div>
                                    )}
                                </div>
                                <div style={{float:'left', position:'relative', top:'50%', transform:'translateY(-50%)', marginLeft:'10px'}}>
                                    Parte {key+1} de {[parts.length]}
                                </div>
                                <div style={{clear:'both'}}/>

                            </div>
                        )
                })}
            </div>
            <div style={{clear:'both'}}/>
        </div>
    )
}